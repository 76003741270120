import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GmailLogo from "../../images/GmailLogo.png";
import OutlookLogo from "../../images/OutlookLogo.png";
import SalesdashLogo from "../../images/logo.png";
import ZapierLogo from "../../images/zapierIcon.png";
import CXLogo from "../../images/3CX_logo.png";
import ApolloLogo from "../../images/ApolloLogo.svg";
import CalendlyLogo from "../../images/calendlyLogo.png";
import RingCentralLogo from "../../images/RingCentralLogo.svg";
import TaskTourIntegrate from "../Onboarding/TaskTourIntegrate";
import { BASE_URL, CALENDLY_CLIENT_ID, CX_XML_URL } from "../../config";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "./Integration.scss";
import PostMarkAppModal from "../Reports/PostMarkAppModal";
import PostMarkAppVerificationModal from "../Reports/PostMarkAppVerificationModal";
import ReusableModal from "../Reusables/ReusableModal";
// Redux stuff
import { connect } from "react-redux";
import { authUserSet, companyOnboardingSet } from "../../redux";
import ConnectApolloModal from "../ApolloLeadGen/ConnectApolloModal";

function Integration(props) {
  const [tourComplete, setTourComplete] = useState(false);
  const [integrated, setIntegrated] = useState(false);
  const [postMarkId, setPostMarkId] = useState(null);
  const [postMarkVerified, setPostMarkVerified] = useState(false);
  const [showPostMarkModal, setShowPostMarkModal] = useState(false);
  const [isCalendlyConnected, setIsCalendlyConnected] = useState(false);
  const [isApolloConnected, setIsApolloConnected] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [calendlyLoading, setCalendlyLoading] = useState(false);
  const [mkimHost, setMkimHost] = useState(null);
  const [mkimTextValue, setMkimTextValue] = useState(null);
  const [showRingCentralDisconnectModal, setShowRingCentralDisconnectModal] =
    useState(false);
  const [showApolloModal, setShowApolloModal] = useState(false);
  const [ringCentralLoading, setRingCentralLoading] = useState(false);
  const [apolloLoading, setApolloLoading] = useState(false);
  const [showRingCentralWidget, setShowRingCentralWidget] = useState(false);
  const [enableInboundOutlook, setEnableInboundOutlook] = useState(false);
  const [userData, setUserData] = useState({});
  const [postmarkData, setPostmarkData] = useState({});
  const textAreaRef = useRef(null);
  const cxRef = useRef(null);
  const mkimHostRef = useRef(null);
  const mkimTextValueRef = useRef(null);
  const url_string = window.location.href;
  const url = new URL(url_string);
  const siteName = url.hostname.split(".");
  const errorType = url.searchParams.get("error");
  const location = useLocation();
  const history = useHistory();
  const fetchMailProvider = () => {
    axios({
      method: "GET",
      url: `/users/mail-provider`,
    }).then((res) => {
      setIntegrated(res.data.user.mail_provider);
      setPostMarkId(res.data.user.postmark_signature_id);
      setPostMarkVerified(res.data.user.postmark_signature_verified);
      setIsCalendlyConnected(res.data.user.is_calendly_connected);
      setIsApolloConnected(res.data.user.is_apollo_connected);
      setMkimHost(res.data.user.dkim_host);
      setMkimTextValue(res.data.user.dkim_text_value);
      setPostmarkData(res.data.postmark_signature_details);
    });
  };
  const getUserDetails = () => {
    axios({
      method: "GET",
      url: `/users`,
    }).then((res) => {
      setUserData(res.data.user);
      setShowRingCentralWidget(res.data.user.is_ringcentral_enabled);
      setEnableInboundOutlook(res.data.user.is_inbound_om_enabled);
    });
  };
  useEffect(() => {
    fetchMailProvider();
    getUserDetails();
  }, []);
  useEffect(() => {
    if (errorType === "insufficient_permissions") {
      NotificationManager.error(
        "We need all the permissions to integrate google account"
      );
    }
    if (errorType === "access_denied") {
      NotificationManager.error(
        "There was a problem integrating to your outlook account. Please try again."
      );
    }
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("error")) {
      queryParams.delete("error");
      history.replace({
        search: queryParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType]);
  const disconnectMailProvider = () => {
    axios({
      method: "POST",
      url: `/users/disconnect-mail-provider`,
      data: {},
    })
      .then((res) => {
        fetchMailProvider();
        NotificationManager.success("Mail Provider disconnected");
      })
      .catch((err) =>
        NotificationManager.error("Error disconnecting mail provider")
      );
  };
  const checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      setPostMarkId(res.data.postmark_signature_id);
      setPostMarkVerified(res.data.postmark_signature_verified);
    });
  };
  const checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      verifySignature(res.data.postmark_signature_verified);
      setPostMarkId(res.data.postmark_signature_id);
      setPostMarkVerified(res.data.postmark_signature_verified);
    });
  };
  const verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          setShowVerificationModal(false);
          checkUserProfile();
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  const changeEnableOutlook = (is_inbound_om_enabled) => {
    axios({
      method: "PUT",
      url: `/users/config`,
      data: {
        is_inbound_om_enabled,
      },
    });
  };
  const disconnectRingCentral = () => {
    setRingCentralLoading(true);
    axios({
      method: "PUT",
      url: `/users`,
      data: {
        name: userData.name,
        formatted_mobile: userData.formatted_mobile
          ? userData.formatted_mobile.toString()
          : null,
        country_code:
          userData.country_code === null || userData.country_code === ""
            ? 1
            : userData.country_code,
        daily_digest: userData.daily_digest,
        weekly_digest: userData.weekly_digest,
        is_ringcentral_enabled: !showRingCentralWidget,
        is_apollo_connected: userData.is_apollo_connected,
      },
    })
      .then((res) => window.location.reload())
      .catch((err) => {
        setRingCentralLoading(false);
        NotificationManager.error(
          `Error ${
            showRingCentralWidget ? "disconnecting" : "connecting"
          } ring central.`
        );
      });
  };
  const disconnectApollo = (is_apollo_connected) => {
    setApolloLoading(true);
    axios({
      method: "PUT",
      url: `/users`,
      data: {
        name: userData.name,
        formatted_mobile: userData.formatted_mobile
          ? userData.formatted_mobile.toString()
          : null,
        country_code:
          userData.country_code === null || userData.country_code === ""
            ? 1
            : userData.country_code,
        daily_digest: userData.daily_digest,
        weekly_digest: userData.weekly_digest,
        is_ringcentral_enabled: userData.is_ringcentral_enabled,
        is_apollo_connected,
      },
    })
      .then((res) => window.location.reload())
      .catch((err) => {
        setApolloLoading(false);
        NotificationManager.error(
          `Error ${
            userData.is_apollo_connected ? "disconnecting" : "connecting"
          } apollo.`
        );
      });
  };
  const integrationDecision = () => {
    if (postMarkId === null) {
      setShowPostMarkModal(true);
    } else if (!postMarkVerified) {
      setShowVerificationModal(true);
    }
  };
  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };
  const copy3CXToClipboard = (e) => {
    cxRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };
  const copyHostToClipboard = (e) => {
    mkimHostRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };
  const copyMKIMValueToClipboard = (e) => {
    mkimTextValueRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };
  const disconnectCalendly = () => {
    setCalendlyLoading(true);
    axios({
      method: "POST",
      url: `/calendly/disconnect`,
    })
      .then((res) => {
        fetchMailProvider();
        setCalendlyLoading(false);
        setShowDisconnectModal(false);
      })
      .catch((err) => {
        setCalendlyLoading(false);
        NotificationManager.error("Error disconnecting calendly.");
      });
  };
  return (
    <div className="integration-screen">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "10px" }}>Integrations</h2>
        </div>
      </div>
      <TaskTourIntegrate tourComplete={tourComplete} />
      <PostMarkAppModal
        showOpportunityModal={showPostMarkModal}
        setShowOpportunityModal={() => setShowPostMarkModal(true)}
        handleCloseModal={() => setShowPostMarkModal(false)}
        handleVerificationOpenModal={() => setShowVerificationModal(true)}
        checkUserProfile={checkUserProfile}
      />
      <PostMarkAppVerificationModal
        showOpportunityModal={showVerificationModal}
        setShowOpportunityModal={() => setShowVerificationModal(true)}
        handleCloseModal={() => setShowVerificationModal(false)}
        checkUserProfile={checkUserProfile}
        checkVerification={checkVerification}
      />
      <ReusableModal
        showModal={showDisconnectModal}
        handleCloseModal={() => setShowDisconnectModal(false)}
        submitHandler={disconnectCalendly}
        modalHeading={`Disconnect Calendly`}
        modalPara={`Are you sure you want to disconnect Calendly`}
        modalHeight={`30`}
        modalTop={`25`}
        loading={calendlyLoading}
      />
      <ReusableModal
        showModal={showRingCentralDisconnectModal}
        handleCloseModal={() => setShowRingCentralDisconnectModal(false)}
        submitHandler={disconnectRingCentral}
        modalHeading={`${
          showRingCentralWidget ? "Disconnect" : "Connect"
        } Ring Central`}
        modalPara={`Are you sure you want to ${
          showRingCentralWidget ? "disconnect" : "connect"
        } Ring Central?`}
        modalHeight={`30`}
        modalTop={`25`}
        loading={ringCentralLoading}
      />
      <ConnectApolloModal
        showModal={showApolloModal}
        handleCloseModal={() => setShowApolloModal(false)}
        modalHeight={`30`}
        modalTop={`25`}
        disconnectApollo={disconnectApollo}
        apolloLoading={apolloLoading}
        setApolloLoading={setApolloLoading}
      />
      <div
        // className="tour-integrate-account grid-container"
        className="grid-container"
        style={{
          marginTop: "30px",
          gridTemplateColumns: "auto auto auto auto",
        }}
      >
        <div className="integrationCard">
          <div
            onClick={() => setTourComplete(true)}
            className="integrationCard-container"
          >
            <img
              src={GmailLogo}
              alt=""
              height="80px"
              style={{ marginTop: "20px", marginBottom: "10px" }}
            />
            <br />
            {integrated === "google" ? (
              <button
                type="button"
                onClick={() => disconnectMailProvider()}
                style={{
                  background: "#4BC893",
                  color: "white",
                  padding: "8px 0px",
                  borderRadius: "8px",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "150%",
                  border: "none",
                  outline: "none",
                  width: "140px",
                  marginLeft: "35px",
                }}
              >
                Disconnect
              </button>
            ) : (
              <a
                href={`${BASE_URL}/auth/google-email/login?site_name=${siteName[0]}&user_id=${props.authUser.id}`}
                style={{
                  background: "linear-gradient(45deg, #D44638, #B23121)",
                }}
                className="integrationCard-link"
              >
                Gmail Integration
              </a>
            )}
          </div>
        </div>
        <div className="integrationCard">
          <div
            onClick={() => setTourComplete(true)}
            className="integrationCard-container"
          >
            <img
              src={OutlookLogo}
              alt=""
              height="100px"
              style={{ marginTop: "20px", marginBottom: "10px" }}
            />
            <br />
            {integrated === "outlook" ? (
              <button
                type="button"
                onClick={() => disconnectMailProvider()}
                style={{
                  background: "#4BC893",
                  color: "white",
                  padding: "8px 0px",
                  borderRadius: "8px",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "150%",
                  border: "none",
                  outline: "none",
                  width: "144px",
                  marginLeft: "35px",
                  cursor: "pointer",
                }}
              >
                Disconnect
              </button>
            ) : (
              <a
                href={`${BASE_URL}/auth/outlook-email/login?site_name=${siteName[0]}&user_id=${props.authUser.id}`}
                style={{
                  background: "linear-gradient(45deg, #57D2DA, #5CA9E5)",
                }}
                className="integrationCard-link"
              >
                Outlook Integration
              </a>
            )}
          </div>
          {/* <p
            style={{
              position: "absolute",
              bottom: "10px",
              width: "100%",
              marginLeft: "14%",
            }}
          >
            <label style={{ cursor: "pointer", fontWeight: "500" }}>
              <input
                type="checkbox"
                style={{ transform: "translateY(3px)" }}
                value={enableInboundOutlook}
                checked={enableInboundOutlook}
                onChange={() => {
                  changeEnableOutlook(!enableInboundOutlook);
                  setEnableInboundOutlook(!enableInboundOutlook);
                }}
              />
              &nbsp; Receive inbound emails?
            </label>
          </p> */}
        </div>
        <div className="integrationCard">
          <div
            onClick={() => setTourComplete(true)}
            className="integrationCard-container"
          >
            <img
              src={ApolloLogo}
              alt=""
              style={{
                marginTop: "20px",
                marginBottom: "10px",
                width: "95%",
                height: "100px",
              }}
            />
            <p style={{ margin: "20px -40px", marginTop: "0px" }}>
              Need an Apollo account?{" "}
              <a
                href="https://apollo.partnerlinks.io/yxg2420i2gak"
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer", color: "#41BE89" }}
              >
                Sign up for free here.
              </a>
            </p>
            <div
              style={{ width: "300px", margin: "0 auto", marginLeft: "-33%" }}
            >
              <button
                type="button"
                onClick={() =>
                  isApolloConnected
                    ? disconnectApollo(false)
                    : setShowApolloModal(true)
                }
                style={{
                  background: "linear-gradient(30deg, #FECF40, #FECF40)",
                  color: "black",
                  padding: "8px 0px",
                  borderRadius: "8px",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "150%",
                  border: "none",
                  outline: "none",
                  width: "60%",
                  cursor: "pointer",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                {isApolloConnected ? "Disconnect Apollo" : "Connect Apollo"}
              </button>
            </div>
          </div>
        </div>
        <div className="integrationCard">
          <div
            onClick={() => setTourComplete(true)}
            className="integrationCard-container"
          >
            <div
              style={
                postMarkId !== null && postMarkVerified
                  ? {
                      height: "auto",
                      verticalAlign: "middle",
                      paddingTop: "24px",
                      marginLeft: "-20px",
                    }
                  : {
                      height: "100px",
                      verticalAlign: "middle",
                      paddingTop: "24px",
                    }
              }
            >
              <img
                src={SalesdashLogo}
                alt=""
                height="40px"
                style={{ marginTop: "5px", marginBottom: "3px" }}
              />
            </div>
            {postMarkId !== null && postMarkVerified && (
              <>
                <div
                  className="copyClipboardButtonDiv"
                  style={{
                    width: "110%",
                    borderRadius: "3px",
                    border: "1px solid #6f7272",
                    marginBottom: "5px",
                  }}
                >
                  <label className="copyClipboardLabel">DKIM Host</label>
                  <input
                    value={mkimHost}
                    readOnly
                    className={"copyClipboardInput"}
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      letterSpacing: "0.25px",
                      color: " #2D4C66",
                      width: "90%",
                      border: "none",
                      outline: "none",
                    }}
                    ref={mkimHostRef}
                  />
                  <button
                    type="button"
                    onClick={copyHostToClipboard}
                    className={"copyClipboardButton"}
                  >
                    <i className="fa fa-clipboard" aria-hidden="true"></i>
                  </button>
                </div>
                <div
                  className="copyClipboardButtonDiv"
                  style={{
                    width: "110%",
                    borderRadius: "3px",
                    border: "1px solid #6f7272",
                    marginBottom: "5px",
                  }}
                >
                  <label className="copyClipboardLabel">DKIM Text Value</label>
                  <input
                    value={mkimTextValue}
                    readOnly
                    className={"copyClipboardInput"}
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      letterSpacing: "0.25px",
                      color: " #2D4C66",
                      width: "90%",
                      border: "none",
                      outline: "none",
                    }}
                    ref={mkimTextValueRef}
                  />
                  <button
                    type="button"
                    onClick={copyMKIMValueToClipboard}
                    className={"copyClipboardButton"}
                  >
                    <i className="fa fa-clipboard" aria-hidden="true"></i>
                  </button>
                </div>
                <div
                  style={{
                    border: "1px solid #ebedef",
                    borderRadius: "4px",
                    textAlign: "left",
                    padding: "5px 20px",
                    width: "110%",
                    transform: "translateX(-30px)",
                    marginBottom: "10px",
                  }}
                >
                  <h2 style={{ fontSize: "16px", margin: "5px 0" }}>
                    Return-Path
                  </h2>
                  <div style={{ fontSize: "13px" }}>
                    <span style={{ color: "#666" }}>Hostname:</span>{" "}
                    <code>pm-bounces</code>
                  </div>
                  <div style={{ fontSize: "13px" }}>
                    <span style={{ color: "#666" }}>Type:</span>{" "}
                    <code>CNAME</code>
                  </div>
                  <div style={{ fontSize: "13px" }}>
                    <span style={{ color: "#666" }}>Add this value:</span>{" "}
                    <code>
                      <a
                        href={
                          postmarkData?.ReturnPathDomainCNAMEValue ||
                          "http://pm.mtasv.net"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {postmarkData?.ReturnPathDomainCNAMEValue ||
                          "pm.mtasv.net"}
                      </a>
                    </code>
                  </div>
                </div>
              </>
            )}
            {postMarkId !== null && postMarkVerified ? (
              <button
                type="button"
                style={{
                  background: "#4BC893",
                  color: "white",
                  padding: "8px 0px",
                  borderRadius: "8px",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "150%",
                  border: "none",
                  outline: "none",
                  width: "144px",
                  cursor: "pointer",
                }}
              >
                Connected
              </button>
            ) : (
              <button
                onClick={() => integrationDecision()}
                style={{
                  background: "#252A2D",
                  color: "white",
                  padding: "8px 0px",
                  borderRadius: "8px",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "150%",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                Salesdash Integration (Powered by Postmarkapp)
              </button>
            )}
          </div>
        </div>
        <div className="integrationCard">
          <div
            onClick={() => setTourComplete(true)}
            className="integrationCard-container"
          >
            <img
              src={ZapierLogo}
              alt=""
              style={{ marginTop: "20px", marginBottom: "10px", width: "95%" }}
            />
            <br />
            <div className="copyClipboardButtonDiv">
              <label className="copyClipboardLabel">Salesdash API key</label>
              <input
                value={props.authUser.zap_api_key}
                readOnly
                className={"copyClipboardInput"}
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "0.25px",
                  color: " #2D4C66",
                }}
                ref={textAreaRef}
              />
              <button
                type="button"
                onClick={copyToClipboard}
                className={"copyClipboardButton"}
              >
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </button>
            </div>
            <br />
            <div
              style={{ width: "300px", margin: "0 auto", marginLeft: "-35%" }}
            >
              <a
                href={`https://zapier.com/apps/salesdash-crm/integrations`}
                target="_blank"
                rel="noreferrer"
                style={{
                  background: "linear-gradient(45deg, #ff4a00, #fd7622)",
                }}
                className="integrationCard-link"
              >
                Go To Salesdash Zapier Page
              </a>
            </div>
          </div>
        </div>
        <div className="integrationCard">
          <div
            onClick={() => setTourComplete(true)}
            className="integrationCard-container"
          >
            <img
              src={CXLogo}
              alt=""
              style={{ marginTop: "20px", marginBottom: "10px", width: "95%" }}
            />
            <br />
            <div className="copyClipboardButtonDiv">
              <label className="copyClipboardLabel">Salesdash API key</label>
              <input
                value={props.companyOnboarding.api_key}
                readOnly
                className={"copyClipboardInput"}
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "0.25px",
                  color: " #2D4C66",
                }}
                ref={cxRef}
              />
              <button
                type="button"
                onClick={copy3CXToClipboard}
                className={"copyClipboardButton"}
              >
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </button>
            </div>
            <br />
            <div
              style={{ width: "300px", margin: "0 auto", marginLeft: "-35%" }}
            >
              <a
                href={CX_XML_URL}
                target="_blank"
                rel="noreferrer"
                style={{
                  background: "linear-gradient(45deg, #ff4a00, #fd7622)",
                }}
                className="integrationCard-link"
              >
                Download XML file
              </a>
            </div>
          </div>
        </div>
        <div className="integrationCard">
          <div
            onClick={() => setTourComplete(true)}
            className="integrationCard-container"
          >
            <img
              src={CalendlyLogo}
              alt=""
              style={{ marginTop: "20px", marginBottom: "10px", width: "95%" }}
            />
            <div
              style={{ width: "300px", margin: "0 auto", marginLeft: "-33%" }}
            >
              {isCalendlyConnected ? (
                <button
                  type="button"
                  onClick={() => setShowDisconnectModal(true)}
                  disabled={calendlyLoading}
                  style={{
                    background: "linear-gradient(30deg, #0ae8f0, #006bff)",
                    color: "white",
                    padding: "8px 0px",
                    borderRadius: "8px",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                    border: "none",
                    outline: "none",
                    width: "144px",
                    cursor: "pointer",
                  }}
                >
                  {calendlyLoading ? "Disconnecting..." : "Disconnect"}
                </button>
              ) : (
                <a
                  href={`https://auth.calendly.com/oauth/authorize?client_id=${CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${BASE_URL}/calendly/integration-callback&state=${siteName[0]}:${props.authUser.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="integrationCard-link"
                  style={{
                    background: "linear-gradient(30deg, #0ae8f0, #006bff)",
                  }}
                >
                  Calendly Integration
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="integrationCard">
          <div
            onClick={() => setTourComplete(true)}
            className="integrationCard-container"
          >
            <img
              src={RingCentralLogo}
              alt=""
              style={{
                marginTop: "20px",
                marginBottom: "10px",
                width: "95%",
                height: "100px",
              }}
            />
            <div
              style={{ width: "300px", margin: "0 auto", marginLeft: "-33%" }}
            >
              {showRingCentralWidget ? (
                <button
                  type="button"
                  onClick={() => setShowRingCentralDisconnectModal(true)}
                  disabled={ringCentralLoading}
                  style={{
                    background: "linear-gradient(30deg, #ff8800, #fd7622)",
                    color: "white",
                    padding: "8px 0px",
                    borderRadius: "8px",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                    border: "none",
                    outline: "none",
                    width: "60%",
                    cursor: "pointer",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {ringCentralLoading
                    ? "Disconnecting..."
                    : "Disconnect Ring Central"}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setShowRingCentralDisconnectModal(true)}
                  disabled={ringCentralLoading}
                  style={{
                    background: "linear-gradient(30deg, #ff8800, #fd7622)",
                    color: "white",
                    padding: "8px 0px",
                    borderRadius: "8px",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                    border: "none",
                    outline: "none",
                    width: "60%",
                    cursor: "pointer",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {ringCentralLoading
                    ? "Connecting..."
                    : "Ring Central Integration"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(Integration);
